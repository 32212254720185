/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://uoxrkivufbgu5osijcjap3cdoa.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fosh3vgjbrhytpon2adzjldhfq",
    "aws_cognito_identity_pool_id": "us-east-1:ec74d4c6-4ce1-49d6-bf0a-40e1812e0d73",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_82JhGJCEf",
    "aws_user_pools_web_client_id": "sep7hjijm96kp30v7j42jjvpq",
    "oauth": {
        "domain": "personalwebsitereact1201fea2-1201fea2-staging.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:3000/leds/,https://main.d3asv5ybyyooma.amplifyapp.com/leds/,https://www.steveninouye.com/leds/",
        "redirectSignOut": "http://localhost:3000/leds/,https://main.d3asv5ybyyooma.amplifyapp.com/leds/,https://www.steveninouye.com/leds/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
